import { CommandeModel, InneditContext } from '@innedit/innedit-react';
import { CommandeType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Citation from '../../../components/Citation';
import CommandeRecapitulatif from '../../../components/Commande/Recapitulatif';
import Content from '../../../components/Content';
import Layout from '../../../components/Layout';

const PageFRCheckoutSuccess: FC<PageProps & { id: string }> = function ({
  id,
  location: { pathname },
}) {
  const { t } = useTranslation();
  const { cart, clearCart } = useContext(InneditContext);
  const [commande, setCommande] = useState<CommandeType>();

  useEffect(() => {
    if (cart && cart.length) {
      clearCart();
    }
  }, [cart, clearCart]);

  useEffect(() => {
    if (id) {
      CommandeModel.findByCheckoutSessionId(id)
        .then(document => setCommande(document))
        .catch((error: Error) => {
          console.error(error);
        });
    }
  }, [id]);

  return (
    <Layout
      pathname={pathname}
      showTitle
      title={
        commande?.validatedAt
          ? 'pages.stripe.success.title.validated'
          : 'pages.stripe.success.title.waiting'
      }
    >
      {commande ? (
        <Content className="flex flex-col p-6 max-w-screen-md w-full mx-auto">
          {commande.validatedAt && (
            <>
              <h2 className="text-center">
                {t('pages.stripe.success.commande-enregistree')}
              </h2>
              <Citation>{t('pages.stripe.success.merci-confiance')}</Citation>
              <p className="text-justify mb-3">
                {t('pages.stripe.success.garantir-securite')}
              </p>
              <p className="text-justify mb-3">
                {t('pages.stripe.success.recevrez-mail-directement')}
              </p>
            </>
          )}

          <p className="mt-6">{t('infos.dealer.name')}</p>
        </Content>
      ) : (
        <Content className="flex flex-col p-6 max-w-screen-md w-full mx-auto">
          <h1 className="text-center mb-3">
            {t('pages.stripe.success.erreur-gestion')}
          </h1>
          <p className="text-center mb-3">
            {t('pages.stripe.success.merci-mais-erreur')}
          </p>
          <p className="text-center mb-3">
            {t('pages.stripe.success.occupons-commande-reviendrons')}
          </p>
          <p className="mt-6">{t('infos.dealer.name')}</p>
        </Content>
      )}
      <h2 className="text-center text-3xl mt-12 mb-6">
        {t('pages.stripe.success.recapitulatif')}
      </h2>
      {commande && <CommandeRecapitulatif commande={commande} />}
    </Layout>
  );
};

export default PageFRCheckoutSuccess;
